<template>
  <AppLayout>
    <template v-slot:appContent>
      <section class="users-list-wrapper">
        <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Admin/List</h4>
          <div>
            <router-link :to="can('admin-create') ? {name:'appAdminCreate'} : '#'">
              <span :title="can('admin-create') ? 'Create' : 'Create Forbidden'"
                    class="glow d-flex align-items-center">
                <i class='bx bx-plus bx-flip-vertical'></i>&nbsp;Create</span>
            </router-link>
          </div>
        </div>

        <div class="users-list-filter px-1 my-2 py-2 border rounded">
          <div>
            <div class="row align-items-center ">
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>Show Per Page</label>
                <VueMultiselect v-model="selectedPagination" class="" :options="pagination" :close-on-select="true" label="name" track-by="value" :show-labels="false" :allow-empty="false"/>
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>Role</label>
                <VueMultiselect v-model="selectedRole" class="" :options="roleList" :close-on-select="true" label="name" track-by="value" :show-labels="false" :allow-empty="false"/>
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>State</label>
                <VueMultiselect v-model="selectedState" class="" :options="stateList" :close-on-select="true" label="name" track-by="value" :show-labels="false" :allow-empty="false"/>
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>Status</label>
                <VueMultiselect v-model="selectedStatus" class="" :options="status" :close-on-select="true" placeholder="Select status" label="name" track-by="value" :show-labels="false" :allow-empty="false"/>
              </div>
            </div>
            <div class="row pt-2">
              <div class="col-12 col-sm-12 col-md-9  col-lg-9">
                <input v-model="getAdminsParams.where_has_user_query" class="form-control search-admin-input-element" type="text" placeholder="Search a admin by name or email or phone">
              </div>
              <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                <button type="reset" class="btn btn-primary btn-block glow users-list-clear mb-0" @click="applyAdminsFilter(null)">Filter</button>
              </div>
            </div>
          </div>
        </div>
        <div class="users-list-table">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table id="users-list-datatable" class="table">
                  <thead>
                  <tr>
                    <th class="position-relative" :style="{width:'20%'}">NAME</th>
                    <th class="position-relative" :style="{width:'18%'}">ADDRESS</th>
                    <th class="position-relative" :style="{width:'12%'}">Role</th>
                    <th class="position-relative" :style="{width:'20%'}">EMAIL</th>
                    <th class="position-relative" :style="{width:'15%'}">PHONE</th>
                    <th class="position-relative" :style="{width:'10%'}">STATUS</th>
                    <th :style="{width:'5%'}">ACTIONS</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(admin) in admins" :key="admin.id">
                    <td>
                      <span v-if="admin.user"  class="admin-name" >{{ `${admin.user.first_name} ${admin.user.last_name}` }}</span>
                    </td>
                    <td>
                      <span v-if="admin.address">{{ admin.address.street }}, {{ admin.address.suburb }} {{ admin.address.state }} {{ admin.address.post_code }}, {{ admin.address.country}}</span>
                    </td>
                    <td>
                      <span v-if="admin.user.role">{{ admin.user.role.name  }}</span>
                    </td>
                    <td>
                      <span v-if="admin.user">{{ admin.user.email }}</span>
                    </td>
                    <td>
                      <span v-if="admin.user">{{ admin.user.phone_number }}</span>
                    </td>
                    <td>
                      <span v-if="admin" class="badge" :class="admin.status === 'Active' ? 'badge-light-success' : 'badge-light-danger'">{{ admin.status }}</span>
                    </td>
                    <td>
                      <div class="d-flex align-items-top justify-content-between">
                        <router-link :title="can('admin-view') ? 'View' : 'View Unauthenticated'"
                                     :to="can('admin-view') ? {name:'appAdminView',params:{id:admin.id}} : '#'"
                                     class="text-decoration-none view-edit-delete-icon"
                                     style="height: 18px" >
                          <i class='bx bx-show'></i>
                        </router-link>
                        <button :title="can('admin-delete') ? 'Delete' : 'Delete Unauthenticated'"
                                :disabled="!can('admin-delete')"
                                @click="this.modelIdForDelete = admin.id"
                                class="btn text-decoration-none cursor-pointer view-edit-delete-icon px-0"
                                data-toggle="modal"
                                data-target="#adminDeleteAlertModal">
                          <i class='bx bx-x'></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <ListPagination position="right" :pagination-data="paginateLinks"  @getClickedPage="applyAdminsFilter"/>
              </div>
            </div>
          </div>
        </div>

        <admin-delete-alert-modal :model-id="modelIdForDelete" model-name="admin" modal-name="adminDeleteAlertModal" @confirmModelDeletion="confirmModelDeletion"/>

      </section>
    </template>
  </AppLayout>
</template>

<script>
//component
import AppLayout from '@/layouts/backEnd/AppLayout';
import AdminDeleteAlertModal from '@/components/backEnd/modal/DeleteAlertModal';
import ListPagination from '@/components/backEnd/pagination/ListPagination';

// mixin
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";

// core package
import {
  mapActions,
  mapGetters
} from "vuex";

// package
import VueMultiselect from 'vue-multiselect';

export default {
  name: "AdminList",
  mixins: [ShowToastMessage,Loader, Authorization],
  components: {
    AppLayout,
    AdminDeleteAlertModal,
    ListPagination,
    VueMultiselect
  },
  data() {
    return {
      selectedPagination: {
        value: '',
        name: 'Default'
      },
      selectedRole: {
        value: '',
        name: 'Any'
      },
      selectedState: {
        value:'',
        name: 'Any'
      },
      selectedStatus: {
        value: '',
        name: 'Any'
      },
      getAdminsParams: {
        where_has_user_query: '',
        where_has_address_state: '',
        where_has_user_role_id: '',
        status: '',
        with_relation: ['user', 'user.role', 'address'],
        order_by_id: 'DESC',
        paginate: 1,
        pagination: '',
        page: ''
      },
      modelIdForDelete: '',
      getSettingsParams: {
        type: ['default'],
        key: ['pagination', 'default_state'],
      },
      getRolesParams: {
        type: 1
      },
    };
  },
  watch: {
    selectedPagination(selectedPagination) {
      this.getAdminsParams.pagination = selectedPagination.value;
    },
    selectedState(selectedState) {
      this.getAdminsParams.where_has_address_state = selectedState.value;
    },
    selectedRole(selectedRole) {
      this.getAdminsParams.where_has_user_role_id = selectedRole.value;
    },
    selectedStatus(selectedStatus) {
      this.getAdminsParams.status = selectedStatus.value;
    },
  },
  computed: {
    ... mapGetters({
      admins: 'appAdmins/admins',
      paginateLinks: 'appAdmins/paginateLinks',
    }),
    status() {
      return [
        {value: '', name: 'Any'},
        {value: 0, name: 'Inactive'},
        {value: 1, name: 'Active'},
      ];
    },
    pagination() {
      return [{value: this.$store.getters['appSettings/settingDefaultPagination'].value, name: 'Default'}, {value: 25, name: '25 Entries'}, {value: 50, name: '50 Entries'}, {value: 100, name: '100 Entries'}];
    },
    stateList() {
      return [{value: '', name: 'Any'}, ... this.$store.getters['appSettings/settingDefaultState'].value];
    },
    roleList() {
      let activeRoles = this.$store.getters['appRoles/roles'].map((roleObj) => {
        let roleId = roleObj.id;
        let roleName = roleObj.name;

        return {value: roleId, name: roleName};
      });

      return [{value: '', name: 'None'}, ...activeRoles];
    },
  },
  methods: {
    ... mapActions({
      resetRoles: 'appRoles/resetRoles',
      resetAdmins: 'appAdmins/resetAdmins',

      getSettings: 'appSettings/getSettings',
      getRoles: 'appRoles/getRoles',
      getAdmins: 'appAdmins/getAdmins',
      deleteAdminOnList: 'appAdmins/deleteAdminOnList'
    }),

    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },

    async getAdminTypeRoleList() {
      await this.getRoles(this.getRolesParams);
    },

    async getAdminList() {
      await this.getAdmins(this.getAdminsParams).then(async (response) => {
            if (response && response.message && response.status !== 200) {
              this.showToastMessage(response);
            }
      });
    },

    async applyAdminsFilter(pageNumber) {
      this.loader(true);
      this.getAdminsParams.page = pageNumber;
      await this.getAdminList();
      this.loader(false);
    },

    async confirmModelDeletion(confirmModelDeletion) {
      await this.deleteSingleAdminOnLIst(confirmModelDeletion.modelId);
    },

    async deleteSingleAdminOnLIst(id) {
      this.deleteAdminOnList(id).then(async (response) => {
        this.showToastMessage(response);
      });
    }
  },

  async mounted() {
    this.loader(true);
    await this.getSettingList();
    await this.getAdminTypeRoleList();
    await this.getAdminList();
    this.loader(false);
  },

  async beforeUnmount() {
    await this.resetRoles();
    await this.resetAdmins();
  },
}
</script>

<style scoped>
.admin-name {
  color: #5A8DEE;
}

.view-edit-delete-icon {
  color: #B3C0CE;
  transition: all 0.2s;
}

.view-edit-delete-icon:hover {
  color: #5A8DEE;
}

.search-admin-input-element {
  min-height: 41px !important;
  padding: 8px 40px 8px 8px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 14px;
}

</style>
